import React, { Component } from 'react'

class Logo extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="138" height="18" viewBox="0 0 138 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask maskUnits="userSpaceOnUse" x="0" y="0" width="138" height="18">
          <path fillRule="evenodd" clipRule="evenodd" d="M0.452942 0H137.678V18H0.452942V0Z" fill={color}/>
        </mask>
        <g mask="url(#mask0_111_5382)">
          <path fillRule="evenodd" clipRule="evenodd" d="M9.86015 18C15.6287 18 19.4109 13.7873 18.8595 7.82704H8.25647V10.4596H15.0299C14.9103 12.8537 12.7092 14.697 9.98015 14.697C6.74888 14.697 4.42687 12.2797 4.42687 8.97588C4.42687 5.64853 6.70063 3.32735 9.86015 3.32735C11.8708 3.32735 13.738 4.45268 14.2646 6.00811L17.9024 5.19452C17.0406 2.17851 13.7145 0 9.86015 0C4.40213 0 0.452942 3.6861 0.452942 8.97588C0.452942 14.2657 4.40213 18 9.86015 18ZM19.3147 17.4969H23.3356L24.3892 14.4574H31.4744L32.528 17.4969H36.5493L30.8278 0.454835H25.0832L19.3147 17.4969ZM25.2513 11.5366L27.8368 3.85393H28.0756L30.6128 11.5366H25.2513ZM38.2251 17.4969H42.1982V11.5131H44.1132L48.9964 17.4969H53.5443L48.0863 11.2261L48.278 11.2018C51.0071 10.8666 52.993 8.85588 52.993 6.05636C52.993 2.68076 50.3605 0.454835 46.3626 0.454835H38.2251V17.4969ZM42.1977 8.71238V3.47085H45.8356C47.7271 3.47085 49.0429 4.45268 49.0429 6.07986C49.0429 7.70704 47.7506 8.71238 45.8356 8.71238H42.1977ZM59.8155 17.4969H63.7886V3.75785H69.2219V0.454835H54.381V3.75785H59.8155V17.4969ZM71.2089 17.4969H82.9612V14.0986H75.182V0.454835H71.2089V17.4969ZM84.9956 17.4969H89.0178L90.0701 14.4574H97.1553L98.2089 17.4969H102.23L96.5088 0.454835H90.765L84.9956 17.4969ZM90.9317 11.5366L93.5172 3.85393H93.756L96.2932 11.5366H90.9317ZM103.905 17.4969H107.687V5.62503H107.926L114.39 17.4969H118.985V0.454835H115.203V12.3515H114.964L108.502 0.454835H103.905V17.4969ZM121.474 17.4969H127.865C133.752 17.4969 137.678 14.0986 137.678 8.97588C137.678 3.85393 133.752 0.454835 127.865 0.454835H121.474V17.4969ZM125.352 14.3857V3.5661H127.865C131.36 3.5661 133.729 5.67286 133.729 8.97588C133.729 12.3032 131.36 14.3857 127.865 14.3857H125.352Z" fill={color}/>
        </g>
      </svg>
    )
  }
}

class ProjectsLogo extends Component {
  render() {
    let { color } = this.props
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 380 97.1">
        <path fill="#FFFFFF" d="M345.9,41.4V10.3h7
          c9.7,0,16.2,6.1,16.2,15.6c0,9.6-6.6,15.6-16.2,15.6H345.9z M335.1,50.3h17.7c16.3,0,27.2-9.8,27.2-24.5
          c0-14.7-10.9-24.5-27.2-24.5h-17.7V50.3z M286.5,50.3h10.5V16.2h0.7l17.9,34.2h12.7v-49h-10.5v34.2h-0.7L299.2,1.3h-12.7V50.3z
          M250.6,33.2l7.2-22.1h0.7l7,22.1H250.6z M234.1,50.3h11.1l2.9-8.7h19.6l2.9,8.7h11.1L266,1.3h-15.9L234.1,50.3z M195.9,50.3h32.5
          v-9.8h-21.5V1.3h-11V50.3z M164.4,50.3h11V10.8h15V1.3h-41.1v9.5h15V50.3z M115.6,25.1V10h10.1c5.2,0,8.9,2.8,8.9,7.5
          c0,4.7-3.6,7.6-8.9,7.6H115.6z M104.6,50.3h11V33.1h5.3l13.5,17.2H147l-15.1-18l0.5-0.1c7.6-1,13.1-6.7,13.1-14.8
          c0-9.7-7.3-16.1-18.4-16.1h-22.5V50.3z M68.7,33.2l7.2-22.1h0.7l7,22.1H68.7z M52.2,50.3h11.1l2.9-8.7h19.6l2.9,8.7H100l-15.8-49
          H68.2L52.2,50.3z M26.1,51.8c16,0,26.4-12.1,24.9-29.3H21.6v7.6h18.8c-0.3,6.9-6.4,12.2-14,12.2c-8.9,0-15.4-7-15.4-16.5
          c0-9.6,6.3-16.2,15-16.2c5.6,0,10.7,3.2,12.2,7.7l10.1-2.3C45.9,6.3,36.7,0,26.1,0C10.9,0,0,10.6,0,25.8C0,41,10.9,51.8,26.1,51.8"
          />
        <path fill="#FFFFFF" d="M279.1,97.1
          c5.4,0,8.6-2.5,8.6-6.6c0-4.9-4.5-5.7-8.2-6.3c-2.9-0.4-5.5-0.9-5.5-3.3c0-1.9,1.6-3.2,4.6-3.2c3.2,0,5,1.5,5,4.2h3.5
          c0-4.4-3.3-7.2-8.6-7.2c-5.2,0-8.3,2.6-8.3,6.5c0,4.8,4.6,5.7,8.3,6.2c2.9,0.4,5.4,0.9,5.4,3.4c0,2.1-1.7,3.3-4.7,3.3
          c-3.5,0-5.6-1.7-5.6-4.5H270C270,94.1,273.5,97.1,279.1,97.1 M255.8,96.4V78.5h6.8v-3.2h-17.4v3.2h6.8v17.9H255.8z M228.1,97.1
          c5,0,9.2-3.1,10.1-7.5l-3.5-0.6c-0.8,2.8-3.5,4.9-6.6,4.9c-4.3,0-7.3-3.3-7.4-8c0-4.7,3.1-8,7.4-8c3.1,0,5.9,2.1,6.5,5.1l3.6-0.4
          c-1-4.6-5.2-7.8-10.2-7.8c-6.4,0-11,4.7-11,11.2C217.1,92.4,221.7,97.1,228.1,97.1 M209.4,96.4v-3.2h-11.5V87h10.2v-2.9h-10.2v-5.6
          h11.2v-3.1h-14.9v21.1H209.4z M177,96.9c3.9,0,6.7-2.9,6.7-7.1V75.3H180v14.4c0,2.5-1.6,4.1-3.9,4.1c-0.8,0-2.1-0.2-3.1-0.5v3
          C174.2,96.7,175.8,96.9,177,96.9 M154.6,93.9c-4.6,0-7.8-3.3-7.8-8c0-4.7,3.2-8,7.8-8c4.6,0,7.8,3.3,7.8,8
          C162.4,90.6,159.2,93.9,154.6,93.9 M154.6,97.1c6.8,0,11.5-4.6,11.5-11.2c0-6.6-4.7-11.2-11.5-11.2c-6.8,0-11.5,4.6-11.5,11.2
          C143.1,92.5,147.8,97.1,154.6,97.1 M121.4,78.4h5.6c2.6,0,4.4,1.4,4.4,3.6c0,2.2-1.7,3.6-4.4,3.6h-5.6V78.4z M121.4,96.4v-7.8h3.1
          l6.7,7.8h4.5l-7.2-8.1h0.2c3.7-0.3,6.3-2.8,6.3-6.3c0-4-3.2-6.6-7.9-6.6h-9.6v21.1H121.4z M95.9,78.4h5c2.7,0,4.4,1.4,4.4,3.7
          c0,2.2-1.7,3.7-4.4,3.7h-5V78.4z M95.9,96.4v-7.6h5.1c4.7,0,7.9-2.6,8-6.7c0-4.2-3.2-6.7-7.9-6.7h-8.8v21.1H95.9z"/>
      </svg>
    )
  }
}

class Instagram extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.39785 0.454102H10.8401C13.2593 0.454102 15.2376 2.40795 15.2376 4.79485V11.2197C15.2376 13.6071 13.2593 15.5604 10.8401 15.5604H4.39785C1.97861 15.5604 0 13.6071 0 11.2197V4.79485C0 2.40795 1.97858 0.454102 4.39785 0.454102ZM7.57775 3.92873C9.92866 3.92873 11.8364 5.83646 11.8364 8.18737C11.8364 10.5386 9.92866 12.446 7.57775 12.446C5.22627 12.446 3.31883 10.5386 3.31883 8.18737C3.31883 5.83646 5.22627 3.92873 7.57775 3.92873ZM7.57777 5.36712C9.13456 5.36712 10.398 6.63032 10.398 8.18737C10.398 9.74442 9.13454 11.0079 7.57777 11.0079C6.02044 11.0079 4.75724 9.74445 4.75724 8.18737C4.75724 6.63032 6.02044 5.36712 7.57777 5.36712ZM11.6978 3.33849C12.0797 3.33849 12.3893 3.6481 12.3893 4.02971C12.3893 4.4116 12.0797 4.72121 11.6978 4.72121C11.3162 4.72121 11.0066 4.4116 11.0066 4.02971C11.0066 3.6481 11.3162 3.33849 11.6978 3.33849ZM4.92418 1.68807H10.3141C12.338 1.68807 13.9932 3.33512 13.9932 5.34777V10.7647C13.9932 12.7773 12.338 14.4241 10.3141 14.4241H4.92418C2.90031 14.4241 1.24484 12.7773 1.24484 10.7647V5.34772C1.24484 3.33512 2.90031 1.68807 4.92418 1.68807Z" fill={color} />
      </svg>
    )
  }
}

class LinkedIn extends Component {

  render() {

    let { color } = this.props

    return (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.353004 4.48918H3.08172V13.9991H0.353004V4.48918ZM1.64793 3.29965H1.62817C0.640405 3.29965 0 2.57267 0 1.65207C0 0.712639 0.659337 0 1.66686 0C2.67357 0 3.29257 0.710846 3.31232 1.64938C3.31232 2.56998 2.67357 3.29965 1.64793 3.29965ZM14.0001 14H10.9059V9.07786C10.9059 7.78973 10.4219 6.91125 9.35762 6.91125C8.54353 6.91125 8.0908 7.50378 7.88008 8.07658C7.80106 8.28096 7.8134 8.56691 7.8134 8.85376V14H4.74802C4.74802 14 4.78753 5.2816 4.74802 4.48918H7.8134V5.98169C7.99449 5.32911 8.97403 4.39774 10.5372 4.39774C12.4765 4.39774 14.0001 5.76655 14.0001 8.71123V14Z" fill={color} />
      </svg>
    )
  }
}

class YouTube extends Component {

  render() {

    let { color } = this.props

    return (
      <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M19.1905 9.60308V3.12324C19.1905 3.12324 19.1905 0 15.8834 0H3.30598C3.30598 0 0 0 0 3.12324V9.60308C0 9.60308 0 12.7263 3.30598 12.7263H15.8834C15.8834 12.7263 19.1905 12.7263 19.1905 9.60308ZM13.3211 6.37221L7.03859 9.85669V2.88668L13.3211 6.37221Z" fill={color}/>
      </svg>
    )
  }
}

class Facebook extends Component {

  render() {

    let { color } = this.props

    return (
      <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.396 14.6617H2.13133V7.3299H0.5V4.80363H2.13133V3.28693C2.13133 1.22612 3.05198 0 5.6693 0H7.84774V2.5272H6.48621C5.46732 2.5272 5.39999 2.88023 5.39999 3.5391L5.3955 4.80363H7.8627L7.57394 7.3299H5.3955V14.6617H5.396Z" fill={color}/>
      </svg>
    )
  }
}

class RightArrow extends Component {

  render() {

    let { color } = this.props

    return (
      <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.51055 12.7693L8.80002 12.0745L13.873 6.99628L0.769531 6.99628L0.769531 6.00364L13.873 6.00364L8.80002 0.92028L9.51055 0.230652L15.7695 6.5L9.51055 12.7693Z" fill={color} />
      </svg>
    )
  }
}

class PlayButton extends Component {

  render() {

    let { color } = this.props

    return (
      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M40 0C17.934 0 0 17.934 0 40C0 62.066 17.934 80 40 80C62.066 80 80 62.066 80 40C80 17.934 62.066 0 40 0ZM40 4C59.906 4 76 20.094 76 40C76 59.906 59.906 76 40 76C20.094 76 4 59.906 4 40C4 20.094 20.094 4 40 4ZM59 40L29 58V22L59 40Z" fill={color}/>
      </svg>
    )
  }
}

class QuoteMark extends Component {

  render() {

    let { color } = this.props

    return (
     <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.86808 20.8C7.78894 20.8 9.73617 18.7643 9.73617 16.0204C9.73617 13.5421 8.23149 11.6834 5.93021 11.1523L10.0017 0.885106L6.37277 0L0.973617 12.2145C0.354043 13.6306 0 14.7813 0 16.0204C0 18.7643 2.03574 20.8 4.86808 20.8ZM19.3838 20.8C22.2162 20.8 24.2519 18.7643 24.2519 16.0204C24.2519 13.5421 22.7472 11.6834 20.446 11.1523L24.5174 0.885106L20.8885 0L15.4894 12.2145C14.8698 13.6306 14.5157 14.7813 14.5157 16.0204C14.5157 18.7643 16.5515 20.8 19.3838 20.8Z" fill={color} />
     </svg>
    )
  }
}

class UpArrow extends Component {

  render() {

    let { color } = this.props

    return (
      <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.9364 7.1766L14.1619 7.9686L8.50143 2.31391V16.9199L7.39496 16.9199V2.31391L1.72871 7.9686L0.960007 7.1766L7.94824 0.199921L14.9364 7.1766Z" fill={color} />
      </svg>
    )
  }
}

class LeftArrow extends Component {

  render() {

    let { color } = this.props

    return (
      <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.02266 0.108118L5.59108 0.663994L1.53269 4.72653L12.0155 4.72653L12.0155 5.52065L1.53269 5.52065L5.59108 9.58734L5.02266 10.139L0.0154724 5.12356L5.02266 0.108118Z" fill={color} />
      </svg>
    )
  }
}

class Bed extends Component {

  render() {

    let { color } = this.props

    return (
      <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M25.2566 9.21898V8.54758H25.245V7.17868H24.5823V8.54758H11.5777V7.44193C11.5777 5.82643 10.2793 4.51108 8.68384 4.51108H0.184503V0.738281H-0.47821V18.9547H0.184503V14.6235H24.5823V18.9547H25.245V14.6235H25.2566V13.9516H25.245V9.21898H25.2566ZM8.68374 5.18248C9.91411 5.18248 10.9148 6.19633 10.9148 7.44193V8.54758H0.184398V5.18248H8.68374ZM0.184398 13.9516H24.5822V9.21898H0.184398V13.9516Z" fill={color} />
      </svg>
    )
  }
}

class Bath extends Component {

  render() {

    let { color } = this.props

    return (
      <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.319 5.07434C11.3503 4.09304 9.71149 4.04826 8.66463 4.91652L5.39129 1.6026C4.54933 0.749996 3.34223 0.506518 2.24339 0.969126C1.14326 1.43043 0.459808 2.46521 0.459808 3.67043V20.8491H1.10073V3.67043C1.10073 2.7326 1.63211 1.92695 2.48868 1.56826C3.34567 1.2113 4.283 1.39869 4.93809 2.0613L8.22002 5.38347C7.87164 5.85565 7.67446 6.42087 7.67446 7.02043C7.67446 7.75695 7.95755 8.44739 8.47132 8.96826L10.8237 11.3487L14.6713 7.45521L12.319 5.07434ZM8.92446 8.50956C8.53183 8.1113 8.31532 7.58304 8.31532 7.02043C8.31532 6.45782 8.53183 5.92956 8.92446 5.53304C9.31751 5.13521 9.83944 4.91608 10.3953 4.91608C10.9507 4.91608 11.4731 5.13521 11.8657 5.53304L13.7649 7.45521L10.8236 10.4313L8.92446 8.50956Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.8951 12.4824L13.0347 14.0498L13.5506 13.6646L12.411 12.0972L11.8951 12.4824Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.0618 15.4624L15.6026 17.5824L16.1186 17.1971L14.5777 15.0771L14.0618 15.4624Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.631 18.9965L17.7702 20.5639L18.2862 20.1787L17.1469 18.6113L16.631 18.9965Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.2767 9.76313L15.7775 8.54443L15.3759 9.05096L16.8746 10.2701L17.2767 9.76313Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.2261 11.3688L20.2529 13.017L20.6549 12.5101L18.6282 10.8618L18.2261 11.3688Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.0064 13.6089L21.6048 14.1158L23.1035 15.3345L23.5056 14.828L22.0064 13.6089Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.8798 12.1203L14.5077 10.7568L14.0584 11.2207L15.4304 12.5842L15.8798 12.1203Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.934 14.0791L19.1901 16.3218L19.6394 15.8583L17.3833 13.6152L16.934 14.0791Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.694 17.8169L22.0656 19.1804L22.5149 18.7165L21.1433 17.353L20.694 17.8169Z" fill={color}/>
      </svg>
    )
  }
}

class Car extends Component {

  render() {

    let { color } = this.props

    return (
      <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.29636 11.2987H16.4886V10.4805H9.29636V11.2987Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.8355 2.54684C20.5212 1.48152 19.5229 0.738281 18.4105 0.738281H7.37419C6.26177 0.738281 5.26354 1.48152 4.94983 2.54684L4.23901 4.94974H0.656677V8.47484H2.67174C2.15787 9.13053 1.83838 9.94534 1.83838 10.84V14.6821H2.98096V18.7383H6.877V14.6821H18.9077V18.7383H22.8044V14.6821H23.9463V10.84C23.9463 9.94534 23.6275 9.13053 23.1136 8.47484H24.9689V4.94974H21.5457L20.8355 2.54684ZM5.86907 2.81526C6.0641 2.15382 6.68253 1.69177 7.37411 1.69177H18.4104C19.102 1.69177 19.7211 2.15382 19.9155 2.81526L21.1196 6.87909H4.66555L5.86907 2.81526ZM1.61406 7.52136V5.90324H3.95759L3.411 7.7495C3.22944 7.88499 3.05815 8.03197 2.9029 8.19621V7.52136H1.61406ZM3.93821 17.7848H5.91991V14.6821H3.93821V17.7848ZM19.8655 17.7848H21.8472V14.6821H19.8655V17.7848ZM22.9894 13.7286H2.79582V10.84C2.79582 9.2347 4.10648 7.92716 5.71801 7.92716H20.0672C21.6788 7.92716 22.9894 9.2347 22.9894 10.84V13.7286ZM24.0116 5.90324V7.52136H22.7233V8.03709C22.6136 7.93356 22.495 7.83961 22.3737 7.74951L21.8278 5.90324H24.0116ZM7.57811 10.9656C7.57811 10.0618 6.84897 9.32919 5.94953 9.32919C5.0501 9.32919 4.32096 10.0618 4.32096 10.9656C4.32096 11.8693 5.0501 12.6019 5.94953 12.6019C6.84897 12.6019 7.57811 11.8693 7.57811 10.9656ZM4.80942 10.9656C4.80942 10.3329 5.31981 9.8201 5.94942 9.8201C6.57902 9.8201 7.08942 10.3329 7.08942 10.9656C7.08942 11.5982 6.57902 12.111 5.94942 12.111C5.31981 12.111 4.80942 11.5982 4.80942 10.9656ZM19.3853 9.32919C20.2847 9.32919 21.0138 10.0618 21.0138 10.9656C21.0138 11.8693 20.2847 12.6019 19.3853 12.6019C18.4858 12.6019 17.7567 11.8693 17.7567 10.9656C17.7567 10.0618 18.4858 9.32919 19.3853 9.32919ZM19.3851 9.8201C18.7555 9.8201 18.2451 10.3329 18.2451 10.9656C18.2451 11.5982 18.7555 12.111 19.3851 12.111C20.0147 12.111 20.5251 11.5982 20.5251 10.9656C20.5251 10.3329 20.0147 9.8201 19.3851 9.8201Z" fill={color}/>
      </svg>
    )
  }
}

class Close extends Component {

  render() {

    let { color } = this.props

    return (
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.336 11.4595L11.6032 12.1924L0.836516 1.42569L1.56934 0.692871L12.336 11.4595Z" fill={color} />
          <path d="M0.836361 11.4595L1.56918 12.1924L12.3358 1.42569L11.603 0.692871L0.836361 11.4595Z" fill={color} />
      </svg>
    )
  }
}

class DownArrow extends Component {

  render() {

    let { color } = this.props

    return (
      <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.75 6.25L9 0.25H0.5L4.75 6.25Z" fill={color}/>
      </svg>
    )
  }
}

class Size extends Component {

  render() {

    let { color } = this.props

    return (
     <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.132324 19.2317H15.0126V4.36108H0.132324V19.2317ZM0.768794 18.5955H14.3746V4.99767H0.768794V18.5955Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.58528 0L0 1.58421L1.58528 3.16885L2.03572 2.71829L0.900449 1.58421L2.03572 0.45056L1.58528 0Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.5583 0L13.1079 0.45056L14.2428 1.58421L13.1079 2.71829L13.5583 3.16885L15.1436 1.58421L13.5583 0Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0.450684 1.9027H14.6935V1.26611H0.450684V1.9027Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.2202 5.81304L16.6707 6.2636L17.8055 5.12909L18.9399 6.2636L19.3904 5.81304L17.8055 4.22925L16.2202 5.81304Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.805 18.4639L16.6702 17.3293L16.2197 17.7795L17.805 19.3641L19.3899 17.7795L18.9399 17.3293L17.805 18.4639Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.4863 18.9139H18.1233V4.67944H17.4863V18.9139Z" fill={color}/>
      </svg>
    )
  }
}

class Floor extends Component {
  render () {
    let { color } = this.props
    return (
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g fill={color} fillRule="nonzero">
                <polygon id="Path" points="1.68548387 0.0758064516 0.0677419355 1.69193548 1.68548387 3.30806452 2.14516129 2.85 0.987096774 1.69193548 2.14516129 0.533870968"></polygon>
                <polygon id="Path" points="13.8983871 0.0758064516 13.4387097 0.533870968 14.5967742 1.69193548 13.4387097 2.85 13.8983871 3.30806452 15.5145161 1.69193548"></polygon>
                <rect id="Rectangle" x="0.527419355" y="1.36612903" width="14.5274194" height="1"></rect>
                <polygon id="Path" points="19.3870968 6.46774194 18.2290323 5.30967742 17.0709677 6.46774194 16.6129032 6.00806452 18.2290323 4.39032258 19.8451613 6.00806452"></polygon>
                <polygon id="Path" points="18.2290323 19.8387097 16.6129032 18.2225806 17.0709677 17.7629032 18.2290323 18.9193548 19.3870968 17.7629032 19.8451613 18.2225806"></polygon>
                <rect id="Rectangle" x="17.9032258" y="4.85" width="1" height="14.5290323"></rect>
                <path d="M15.366129,19.6870968 L0.196774194,19.6870968 L0.196774194,10.2870968 L8.02580645,4.48064516 L15.366129,10.3225806 L15.366129,19.6870968 Z M0.777419355,19.1064516 L14.7854839,19.1064516 L14.7854839,10.5951613 L8.01129032,5.21612903 L0.777419355,10.5790323 L0.777419355,19.1064516 Z" id="Shape"></path>
              </g>
          </g>
      </svg>
    )
  }
}

class Area extends Component {

  render() {

    let { color } = this.props

    return (
     <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.132324 19.2317H15.0126V4.36108H0.132324V19.2317ZM0.768794 18.5955H14.3746V4.99767H0.768794V18.5955Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.58528 0L0 1.58421L1.58528 3.16885L2.03572 2.71829L0.900449 1.58421L2.03572 0.45056L1.58528 0Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.5583 0L13.1079 0.45056L14.2428 1.58421L13.1079 2.71829L13.5583 3.16885L15.1436 1.58421L13.5583 0Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0.450684 1.9027H14.6935V1.26611H0.450684V1.9027Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.2202 5.81304L16.6707 6.2636L17.8055 5.12909L18.9399 6.2636L19.3904 5.81304L17.8055 4.22925L16.2202 5.81304Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.805 18.4639L16.6702 17.3293L16.2197 17.7795L17.805 19.3641L19.3899 17.7795L18.9399 17.3293L17.805 18.4639Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.4863 18.9139H18.1233V4.67944H17.4863V18.9139Z" fill={color}/>
      </svg>
    )
  }
}

class Parking extends Component {

  render() {

    let { color } = this.props

    return (
     <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.5145 5.20557H7.87354V14.3406H9.02694V10.8031H11.5145C13.4579 10.8031 14.7921 9.72787 14.7921 8.00455C14.8052 6.25548 13.5094 5.20557 11.5145 5.20557ZM11.5146 9.75362H9.027V6.24236H11.5146C12.8352 6.24236 13.6266 6.92888 13.6266 8.00455C13.6266 9.06711 12.8352 9.75362 11.5146 9.75362Z" fill={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.5766 0C5.25816 0 0.930664 4.32749 0.930664 9.64589C0.930664 14.9643 5.25816 19.2918 10.5766 19.2918C15.895 19.2918 20.2225 14.9643 20.2225 9.64589C20.2225 4.32749 15.895 0 10.5766 0ZM10.5765 18.5931C5.64299 18.5931 1.62926 14.5794 1.62926 9.64589C1.62926 4.71243 5.64299 0.69869 10.5765 0.69869C15.5099 0.69869 19.5237 4.71243 19.5237 9.64589C19.5237 14.5794 15.5099 18.5931 10.5765 18.5931Z" fill={color} />
    </svg>
    )
  }
}

class Zone extends Component {

  render() {

    let { color } = this.props

    return (
     <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.1646 11.6062C10.9189 11.5745 10.694 11.747 10.6623 11.9928C10.6306 12.2386 10.8031 12.4627 11.0488 12.4952C13.8477 12.8635 15.1048 13.6283 15.1048 14.0724C15.1048 14.6948 12.6616 15.8495 7.9968 15.8495C3.33198 15.8495 0.88878 14.6947 0.88878 14.0724C0.88878 13.45 3.18121 12.3752 7.55282 12.2952V13.6283C7.55282 13.8733 7.75107 14.0724 7.9968 14.0724C8.24253 14.0724 8.44079 13.8733 8.44079 13.6283V7.82039C9.64865 7.66792 10.694 6.90891 11.2122 5.80746C11.7303 4.70683 11.6495 3.4171 10.9981 2.38904C10.3458 1.36174 9.21379 0.738525 7.99682 0.738525C6.77985 0.738525 5.64767 1.36174 4.99555 2.38904C4.34414 3.41716 4.26334 4.70687 4.78147 5.80746C5.2996 6.90891 6.34502 7.66793 7.55286 7.82039V11.4062C3.7276 11.4729 0 12.3835 0 14.0724C0 15.7612 4.02081 16.7385 7.99684 16.7385C11.9729 16.7385 15.9937 15.8229 15.9937 14.0724C15.9937 12.4285 12.6175 11.8019 11.1647 11.6063L11.1646 11.6062ZM5.33119 4.29671C5.33119 3.21776 5.98094 2.24628 6.97636 1.83299C7.97264 1.42057 9.11973 1.64886 9.88188 2.4112C10.6441 3.17354 10.8723 4.31998 10.4591 5.31644C10.0468 6.31291 9.07469 6.96279 7.99676 6.96279C6.52485 6.96279 5.33114 5.76885 5.33114 4.29665L5.33119 4.29671Z" fill={color} />
     </svg>
    )
  }
}

class Search extends Component {

  render() {

    let { color } = this.props

    return (
     <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.8167 11.8167C14.1753 9.45926 14.1753 5.63588 11.8167 3.27865C9.45931 0.920062 5.63597 0.920062 3.27876 3.27865C0.920193 5.63607 0.920193 9.45944 3.27876 11.8167C5.63615 14.1753 9.45949 14.1753 11.8167 11.8167ZM12.325 13.3922C9.36029 15.8204 4.97805 15.6518 2.21027 12.8839C-0.736756 9.9369 -0.736756 5.15834 2.21027 2.21029C5.15847 -0.736763 9.93701 -0.736763 12.8838 2.21029C15.6516 4.9781 15.8202 9.36048 13.3921 12.3251L17.9996 16.9327L16.9324 18L12.325 13.3922Z" fill={color} />
     </svg>

    )
  }
}

class Menu extends Component {

  render() {

    let { color } = this.props

    return (
     <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.615385 0.615385H19.0769" stroke={color} strokeLinecap="square"/>
      <path d="M0.615385 7.99991H19.0769" stroke={color} strokeLinecap="square"/>
      <path d="M0.615385 15.3847H19.0769" stroke={color} strokeLinecap="square"/>
     </svg>
    )
  }
}

class FloorPlan extends Component {

  render() {

    let { color } = this.props

    return (
     <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.4998 9.5496L18.249 8.84961L15.9986 6.39965V0H0V16H15.9986V11.1001H14.9992V14.9994H8.09905V13.1496H7.09964V14.9994H0.999234V7.85038H7.09964V9.95034H8.09905V6.8496H0.999409V0.999444H14.9992V3.5998H11.9996V4.59921H14.9992V6.74975L17.4998 9.5496Z" fill={color}/>
      </svg>
    )
  }
}

class Download extends Component {

  render() {

    let { color } = this.props

    return (
     <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.89524 0V9.96706L4.01792 5.84613L3.26764 6.57432L8.4142 12.0455L13.5608 6.57432L12.8105 5.84613L8.93317 9.96706V0L7.89524 0Z" fill={color} />
      <path d="M0.108398 15.2376H16.7203V9.17316H15.682V14.1829H1.14664V9.17316H0.108398V15.2376Z" fill={color} />
     </svg>
    )
  }
}

export { Logo, ProjectsLogo, Instagram, LinkedIn, YouTube, Facebook, RightArrow, PlayButton, QuoteMark, UpArrow, Bed, Bath, Car, Area, Parking, Floor, Zone, Close, DownArrow, Search, Menu, FloorPlan, Download, LeftArrow}
