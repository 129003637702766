import React, { Component } from 'react'
import NewsletterForm from './newsletter-form'

class Newsletter extends Component {

  render() {

    return (
      <section className='newsletter'>
        <div className='newsletter__inner'>
          <NewsletterForm />
        </div>
      </section>
    )
  }
}

export default Newsletter
