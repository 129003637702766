import React, { useState, useEffect } from 'react';
import { Close } from './icons';
import NewsletterPopupForm from './newsletter-popup-form';

const Popup = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Check if the popup has been shown before
    const popupShown = localStorage.getItem('popupShown');
    if (!popupShown) {
      // Set a timeout to open the popup after 4 seconds
      const timer = setTimeout(() => {
        setIsOpen(true);
      }, 3000);

      // Cleanup the timeout if the component unmounts before the timer completes
      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem('popupShown', 'true');
  };

  return (
    isOpen && (
      <div className='popup'>
        <div className='popup__wrapper'>
          <button className='close' onClick={handleClose}><Close /> Close</button>
          <div className='popup__form'>
            <h3>Interested in finding out more?<br /> 
            Sign up to our newsletter.</h3>
            <NewsletterPopupForm />
          </div>
        </div>
      </div>
    )
  );
};

export default Popup;