import React, { Component } from 'react'
import { RightArrow } from './icons'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class NewsletterForm extends Component {

  state = {
    email: '',
  }

  _submitForm = () => {    
    document.getElementById('newsletter-submit').click();
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {

    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
    .then(() => {
      this.setState({result: 'success', msg: ''})
    })
    .catch(error => this.setState({result: 'fail', msg: error}))
  }

  render() {
    let props = {
      ref: 'form',
      name: 'newsletter',
      className: 'form',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    let { theme } = this.props

    if (this.state.result === 'success')
    return (
      <p className='contact__success'>Thank you.</p>
    )

    return (
      <form {...props}>

        <div className='form__row'>

          <div className="form__newsletter">

            <input type='email' name='email' id='newsletter-email' placeholder='Your email address' onChange={this.handleChange} required />

            <button 
              type='submit'
            >
              <RightArrow color='#E7E5E1' />
            </button>
            
          </div>

        </div>

      </form>
    )
  }
}

export default NewsletterForm