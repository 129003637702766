import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../components/header'
import Footer from '../components/footer'
import ScrollBack from '../components/scroll-back'
import Popup from '../components/popup'

import '../assets/scss/main.scss'

class Layout extends Component {

  render() {
    let { children } = this.props
    return (
      <>
        <Header />
        <main>{children}</main>
        <ScrollBack />
        <Footer />
        <Popup />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout