import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo, ProjectsLogo, Search, Menu, Close, LeftArrow } from './icons'
import SearchForm from './search'

class Header extends Component {

  state = {
    offCanvas: false,
    offCanvasSearch: false,
    offCanvasSubNav: false,
    offCanvasSubNavItem: [],
    scrolled: false,
    isMobile: false,
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions)
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions)
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = () => {
    this.setState({
      scrolled: window.scrollY > 1,
    })
  }

  updateDimensions = () => {
    if(window.innerWidth < 900) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
    this._clearCanvasSubNav()
    document && document.querySelector('body').classList.remove('off-canvas-open')
  }

  _hideOffSearch = () => {
    this._hideSearchForm()
    this.setState({ offCanvasSearch: false })
  }

  _toggleOffCanvas = () => {
    this._hideOffSearch()
    this._clearCanvasSubNav()
    document && document.querySelector('body').classList.toggle('off-canvas-open')
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _showSearchForm() {
    document && document.documentElement.style.setProperty('--search-form-display', 'block')
    document && document.documentElement.style.setProperty('--search-form-opacity', 1.0)
    document && document.documentElement.style.setProperty('--search-form-visibility', 'visible')
    document && document.querySelector('body').classList.add('search-open')
  }

  _hideSearchForm() {
    document && document.documentElement.style.setProperty('--search-form-display', 'none')
    document && document.documentElement.style.setProperty('--search-form-opacity', 0)
    document && document.documentElement.style.setProperty('--search-form-visibility', 'hidden')
    document && document.querySelector('body').classList.remove('search-open')
  }

  _toggleOffSearch = () => {

    let { isMobile } = this.state
    this._hideOffCanvas()
    if( isMobile ) {
      let showing = this.state.offCanvasSearch
      if( showing ) {
        this._hideSearchForm()
      } else {
        this._showSearchForm()
      }
      this.setState({ offCanvasSearch: !showing })
    } else {
      this._showSearchForm()
    }
  }

  _clearCanvasSubNav() {
    this.setState({ offCanvasSubNavItem : false })
  }

  _toggleOffCanvasSubNav = (key) => {
    let tempOffCanvasSubNavItem = [];
    let { offCanvasSubNavItem } = this.state
    tempOffCanvasSubNavItem[key] = !offCanvasSubNavItem[key]
    this.setState({ offCanvasSubNavItem : tempOffCanvasSubNavItem, offCanvasSubNav : true })
  }

  render() {

    let { theme } = this.props

    let { offCanvas, offCanvasSearch, scrolled, isMobile, offCanvasSubNav, offCanvasSubNavItem } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'

    if( isMobile ) headerClass += ' header--is-mobile'
    else headerClass += ' header--is-desktop'

    if (offCanvas) headerClass += ' header--off-canvas'
    else if (scrolled) headerClass += ' header--scrolled'
    
    if( offCanvas && !offCanvasSubNavItem[0] && !offCanvasSubNavItem[1] ) headerClass += ' header--default-theme'
    else if( offCanvasSubNavItem[0] ) headerClass += ' header--light-theme'
    else if( offCanvasSubNavItem[1] ) headerClass += ' header--dark-theme'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <div className='header__content'>

              <Link to='/' title='Gartland'>
            
                <div className='header__logo light-theme'>
                  <Logo color='#000000' />
                </div>

                <div className='header__logo dark-theme'>
                  <Logo color='#E7E5E1' />
                </div>

                <div className='header__logo projects-theme'>
                  <ProjectsLogo />
                </div>

              </Link>

              <div className='header__menu'>
                <ul>
                  <li>
                    <button onClick={() => this._toggleOffSearch()}>
                      { offCanvasSearch ? <Close color='#313131' /> : <Search color='#313131' /> }
                    </button>
                  </li>
                  <li>
  
                    <button 
                      onClick={() => this._toggleOffCanvas()}
                      className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' } 
                      type='button'
                    >
                      <span className='lines'></span>
                    </button>

                  </li>
                </ul>
              </div>

            </div>
          </div>
         </header>
         <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <button className='off-canvas__close-bg' type='button' onClick={() => this._toggleOffCanvas()} />
          <div className='off-canvas__inner'>
            <div className='off-canvas__content'>
            <nav className='off-canvas__nav'>
              <ul>
                <li>
                  <button 
                    type='button'
                    className={offCanvasSubNavItem[0] ? 'active' : ''}
                    onClick={() => this._toggleOffCanvasSubNav(0)}
                  >
                    Residential
                  </button>
                </li>
                <li>
                  <button 
                    type='button'
                    className={offCanvasSubNavItem[1] ? 'active' : ''}
                    onClick={() => this._toggleOffCanvasSubNav(1)}
                  >
                    Commercial
                  </button>
                </li>
                <li>
                  <Link to='/projects/' {...props}>
                    Gartland Projects
                  </Link>
                </li>
                <li>
                  <Link to='/about-us/' {...props}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to='/contact-us/' {...props}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>
            <div className={`off-canvas__sub-nav  ${offCanvasSubNavItem[0] ? 'off-canvas__sub-nav--active' : ''}`}>

              <div className={`off-canvas__sub-nav-item`}>

                <div>

                  <button 
                    type='button'
                    className='back'
                    onClick={() => this._toggleOffCanvasSubNav(0)}
                  >
                    <LeftArrow color='#000000' />
                    <span>Back to Main</span>
                  </button>

                  <h4>Residential</h4>

                </div>

                <ul>
                <li><Link to='/buy/' {...props}>Buy Residential</Link></li>
                  <li><Link to='/lease/' {...props}>Lease Residential</Link></li>
                  <li><Link to='/upcoming-auctions/' {...props}>Upcoming Auctions</Link></li>
                  <li><Link to='/upcoming-inspections/' {...props}>Scheduled Inspections</Link></li>
                  <li><Link to='/sold/' {...props}>Recently Sold</Link></li>
                  <li><Link to='/leased/' {...props}>Recently Leased</Link></li>
                  <li><Link to='/appraisals/' {...props}>Request an Appraisal</Link></li>
                  <li><Link to='/property-management/' {...props}>Property Management</Link></li>
                </ul>

              </div>

            </div>

            <div className={`off-canvas__sub-nav ${offCanvasSubNavItem[1] ? 'off-canvas__sub-nav--active' : ''}`}>

              <div className={`off-canvas__sub-nav-item dark-theme`}>

                <div>

                  <button 
                    type='button'
                    className='back'
                    onClick={() => this._toggleOffCanvasSubNav(1)}
                  >
                    <LeftArrow color='#E7E5E1' />
                    <span>Back to Main</span>
                  </button>

                  <h4>Commercial</h4>

                </div>

                <ul>
                <li><Link to='/buy-commercial/' {...props} >Buy Commercial</Link></li>
                  <li><Link to='/lease-commercial/' {...props}>Lease Commercial</Link></li>
                  <li><Link to='/upcoming-commercial-auctions/' {...props}>Upcoming Auctions</Link></li>
                  <li><Link to='/sold-commercial/' {...props}>Recently Sold</Link></li>
                  <li><Link to='/appraisals/' {...props}>Request an Appraisal</Link></li>
                  <li><Link to='/property-management-commerical/' {...props}>Property Management</Link></li>
                </ul>

              </div>

            </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}

export default Header
