import React, { Component } from 'react'
import { RightArrow } from './icons'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class NewsletterPopupForm extends Component {

  state = {
    email: '',
    first_name: '',
    last_name: '',
    mobile: '',
    residential: false,
    commercial: false,
    projects: false,
    initialSubmission: false,
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheckboxChange = e => {
    const { name, type, checked, value } = e.target;
  
    this.setState({ 
      [name]: type === 'checkbox' ? checked : value 
    });
  }

  handleSubmit = e => {

    e.preventDefault()

    this.setState({initialSubmission: true})

    if (!this.refs.form.checkValidity()) return false

    if (this.state.residential || this.state.commercial || this.state.projects) {

      fetch("https://hooks.zapier.com/hooks/catch/3298056/26yszq6/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": this.refs.form.getAttribute("name"),
          ...this.state
        })
      })
      .then(() => {
        this.setState({result: 'success', msg: ''})
      })
      .catch(error => this.setState({result: 'fail', msg: error}))
    }
  }

  render() {
    let props = {
      ref: 'form',
      name: 'newsletter',
      className: 'form',
      onSubmit: this.handleSubmit,
    }

    if (this.state.result === 'success')
    return (
      <p className='contact__success'>Thanks for subscribing. Keep an eye on your inbox.</p>
    )

    return (
      <form {...props}>
        <div className='form__row'>
          <div className='form__checkbox-group'>
            <div className='form__checkbox'>
              <input type='checkbox' id='residential-type' name="residential" onChange={this.handleCheckboxChange} />
              <label htmlFor='residential-type'>Residential</label>
            </div>
            <div className='form__checkbox'>
              <input type='checkbox' id='commercial-type' name="commercial" onChange={this.handleCheckboxChange} />
              <label htmlFor='commercial-type'>Commercial</label>
            </div>
            <div className='form__checkbox'>
              <input type='checkbox' id='projects-type' name="projects" onChange={this.handleCheckboxChange} />
              <label htmlFor='projects-type'>Gartland Projects</label>
            </div>
          </div>
          { this.state.initialSubmission && ( !this.state.residential && !this.state.commercial && !this.state.projects ) && <p className='form__validation'>Please select an option</p> }
        </div>
        <div className='form__row form__row--double'>
          <input type='text' name='first_name' id='newsletter-first' placeholder='First Name' onChange={this.handleChange} required />
          <input type='text' name='last_name' id='newsletter-last' placeholder='Last Name' onChange={this.handleChange} required />
        </div>
        <div className='form__row form__row--double'>
          <input type='email' name='email' id='newsletter-email' placeholder='Email Address' onChange={this.handleChange} required />
          <input type='text' name='mobile' id='newsletter-mobile' placeholder='Mobile' onChange={this.handleChange} required />
        </div>
        <div className='form__row form__row--submit'>
          <button 
            type='submit'
          >
            Subscribe
            <RightArrow color='#E7E5E1' />
          </button> 
        </div>
      </form>
    )
  }
}

export default NewsletterPopupForm