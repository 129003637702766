import React, { Component } from 'react'
import Link from '../utils/link'
import { Instagram, Facebook, LinkedIn, YouTube } from './icons'

class SocialIcons extends Component {

  render() {

    let { color, type } = this.props

    return (
      
      <div className='social-icons'>

        { type !== 'projects' &&
          <ul>
            <li><Link to='https://www.instagram.com/gartlandproperty'><Instagram color={color} /></Link></li>
            <li><Link to='https://www.linkedin.com/company/gartlandproperty'><LinkedIn color={color} /></Link></li>
            <li><Link to='https://www.youtube.com/channel/UCdFj0C3LxWP2jHPGRuvm4cA'><YouTube color={color} /></Link></li>
            <li><Link to='https://www.facebook.com/gartlandproperty'><Facebook color={color} /></Link></li>
          </ul>
        }
        { type === 'projects' &&
          <ul>
            <li><Link to='https://www.instagram.com/gartlandprojects'><Instagram color={color} /></Link></li>
            <li><Link to='https://www.linkedin.com/company/projects-by-gartland/'><LinkedIn color={color} /></Link></li>
            <li><Link to='https://www.facebook.com/projectsbygartland'><Facebook color={color} /></Link></li>
          </ul>
        }

      </div> 
    )
  }
}

export default SocialIcons