import React, { Component } from 'react'

import { UpArrow } from './icons'

class ScrollBack extends Component {

  render() {

    let { theme } = this.props

    return (

      <section className='scroll-back'>

        <div className='scroll-back__inner'>

          <div className='scroll-back__content'>

            <div className='scroll-back__image' onClick={() => window && window.scroll({ top: 0, left: 0, behavior: 'smooth'})}>
              <UpArrow color='#E7E5E1' />
            </div>

          </div>
            
        </div>

      </section>
    )
  }
}

export default ScrollBack