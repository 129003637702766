import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo } from './icons'
import SocialIcons from './social-icons'
import Newsletter from './newsletter'

class Footer extends Component {

  render() {
  
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__content'>
            <div className='footer__cols'>
              
              <div className='footer__col'>
              
                <Link to='/' title='Gartland' className='footer__logo '>
                  <Logo color='#E7E5E1' />
                </Link>

                <div className='address'>

                  <ul>
                    <li className='mobile'>+61 3 5224 2204</li>
                    <li className='email'>info@gartland.com.au</li>
                    <li className='address-name'>71 Little Malop Street</li>
                    <li className='address-suburb'>Geelong VIC 3220</li>
                  </ul>

                </div>

              </div>

              <div className='footer__col'>

                <b><h4>Residential</h4></b>

                <ul>
                  <li><Link to='/buy/'>Buy Residential</Link></li>
                  <li><Link to='/upcoming-auctions/'>Upcoming Auctions</Link></li>
                  <li><Link to='/upcoming-inspections/'>Scheduled Inspections</Link></li>
                  <li><Link to='/sold/'>Recently Sold</Link></li>
                  <li><Link to='/lease/'>Lease Residential</Link></li>
                  <li><Link to='/leased/'>Recently Leased</Link></li>
                  <li><Link to='/property-management/'>Property Management</Link></li>

                </ul>
              </div>

              <div className='footer__col'>

                <b><h4>Commercial</h4></b>

                <ul>
                  <li><Link to='/buy-commercial/'>Buy Commercial</Link></li>
                  <li><Link to='/upcoming-commercial-auctions/'>Upcoming Auctions</Link></li>
                  <li><Link to='/sold-commercial/'>Recently Sold</Link></li>
                  <li><Link to='/lease-commercial/'>Lease Commercial</Link></li>
                  <li><Link to='/property-management-commerical/'>Property Management</Link></li>
                </ul>

              </div>

              <div className='footer__col'>

                <b><h4>About Us</h4></b>

                <ul>
                  <li><Link to='/about-us/'>About Gartland</Link></li>
                  <li><Link to='/our-team/'>Our Agents</Link></li>
                  <li><Link to='/appraisals/'>Request an Appraisal</Link></li>
                  <li><Link to='/projects/'>Gartland Projects</Link></li>
                  <li><Link to='/contact-us/'>Contact Us</Link></li>
                  <li><a href='https://www.homely.com.au/' target='_blank' rel='noopener noreferrer'>Find Gartland on Homely</a></li>
                  <li><a href='https://www.consumer.vic.gov.au/housing/buying-and-selling-property/checklists/due-diligence' target='_blank' rel='noopener noreferrer'>Due Diligence</a></li>
                </ul>

              </div>

              <div className='footer__col'>

              <b><h4>Subscribe to our newsletter</h4></b>

                  <div className='footer__newsletter'>

                    <Newsletter />

                    <div className='footer__social'>

                      <p>Connect</p>

                      <SocialIcons color='#E7E5E1' />

                    </div>

                  </div>

              </div>

            </div>
          </div>
        </div>
        <div className='footer__inner'>
          <div className='footer__credits'>
            <ul>
              <li><Link to='/terms-conditions/'>Terms &amp; Conditions</Link></li>
              <li><Link to='/privacy-policy/'>Privacy Policy</Link></li>
            </ul>
            <p className='footer__copyright'>© Gartland{ new Date().getFullYear() }. <Link to='https://atollon.com.au'>Design By Atollon</Link> </p>
            
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
