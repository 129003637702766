import React, { Component } from 'react'
import Select from 'react-select'
import { Search } from './icons'
import { navigate } from 'gatsby'

let categories = [
  { label: "Buy", value: 'buy' },
  { label: "Lease", value: 'lease' },
  { label: "Sold", value: 'sold' },
  { label: "Auction", value: 'auction'},
]

let commericalCategories = [
  { label: "Buy", value: 'buy-commercial' },
  { label: "Lease", value: 'lease-commercial' },
  { label: "Auction", value: 'auction-commercial'},
]

const rentPrice = [
  { label: "$100", value: 100 },
  { label: "$200", value: 200 },
  { label: "$300", value: 300 },
  { label: "$400", value: 400 },
  { label: "$500", value: 500 },
  { label: "$600", value: 600 },
  { label: "$700", value: 700 },
  { label: "$800", value: 800 },
  { label: "$900", value: 900 },
  { label: "$1000+", value: 10000 },
]

const priceMin = [
  { label: "$500k+", value: 500000},
  { label: "$600k+", value: 600000},
  { label: "$700k+", value: 700000},
  { label: "$800+", value: 800000},
  { label: "$900k+", value: 900000},
  { label: "$1mill+", value: 1000000},
  { label: "$1.1mil+", value: 1100000},
  { label: "$1.2mil+", value: 1200000},
  { label: "$1.3mil+", value: 1300000},
  { label: "$1.4mil+", value: 1400000},
  { label: "$1.5mil+", value: 1500000},
  { label: "$1.6mil+", value: 1600000},
  { label: "$1.7mil+", value: 1700000},
  { label: "$1.8mil+", value: 1800000},
  { label: "$1.9mil+", value: 1900000},
  { label: "$2mil+", value: 2000000},
]
const priceMax = [
  { label: "$600k", value: 600000},
  { label: "$700k", value: 700000},
  { label: "$800", value: 800000},
  { label: "$900k", value: 900000},
  { label: "$1mill", value: 1000000},
  { label: "$1.1mil", value: 1100000},
  { label: "$1.2mil", value: 1200000},
  { label: "$1.3mil", value: 1300000},
  { label: "$1.4mil", value: 1400000},
  { label: "$1.5mil", value: 1500000},
  { label: "$1.6mil", value: 1600000},
  { label: "$1.7mil", value: 1700000},
  { label: "$1.8mil", value: 1800000},
  { label: "$1.9mil", value: 1900000},
  { label: "$2mil", value: 2000000},
  { label: "$2.1mil", value: 2100000},
  { label: "$2.2mil", value: 2200000},
  { label: "$2.5mil", value: 2500000},
  { label: "$3mil+", value: 50000000},
]

const commercialPriceMin = [
  { label: "$500k+", value: 500000},
  { label: "$1mil+", value: 1000000},
  { label: "$1.5mil+", value: 1500000},
  { label: "$2mil+", value: 2000000},
  { label: "$2.5mil+", value: 2500000},
  { label: "$5mil+", value: 5000000},
  { label: "$10mil+", value: 10000000},
  { label: "$15mil+", value: 15000000},
  { label: "$20mil+", value: 20000000},
  { label: "$30mil+", value: 30000000},
]
const commercialPriceMax = [
  { label: "$1mil", value: 1000000},
  { label: "$1.5mil", value: 1500000},
  { label: "$2mil", value: 2000000},
  { label: "$2.5mil", value: 2500000},
  { label: "$5mil", value: 5000000},
  { label: "$10mil", value: 10000000},
  { label: "$15mil", value: 15000000},
  { label: "$20mil", value: 20000000},
  { label: "$30mil", value: 30000000},
  { label: "$40mil", value: 40000000},
]

const commercialPricePaMin = [
  { label: "$10k+", value: 10000},
  { label: "$20k+", value: 20000},
  { label: "$30k+", value: 30000},
  { label: "$40k+", value: 40000},
  { label: "$50k+", value: 50000},
  { label: "$75k+", value: 75000},
  { label: "$100k+", value: 100000},
  { label: "$150k+", value: 150000},
  { label: "$300k+", value: 300000},
  { label: "$500k+", value: 500000},
  { label: "$1mil+", value: 1000000},
]
const commercialPricePaMax = [
  { label: "$20k", value: 20000},
  { label: "$30k", value: 30000},
  { label: "$40k", value: 40000},
  { label: "$50k", value: 50000},
  { label: "$75k", value: 75000},
  { label: "$100k", value: 100000},
  { label: "$150k", value: 150000},
  { label: "$300k", value: 300000},
  { label: "$500k", value: 500000},
  { label: "$1mil", value: 1000000},
  { label: "$2mil+", value: 10000000},
]

const bed_bath_park = [
  { label: "1+", value: 1},
  { label: "2+", value: 2},
  { label: "3+", value: 3},
  { label: "4+", value: 4},
  { label: "5+", value: 5},
  { label: "6+", value: 6}
]

function getType(type, value) {

  let typeArr = type ? type.split('-') : []

  let options = value === "commercial" ? commericalCategories : categories

  if(options.length > 0 ) {

    type = options[0].value

    for (let i = 0; i < options.length; i++) {

        if( options[i].value && typeArr[0] === options[i].value.split('-')[0]) {
          type = options[i].value
        }
    }
  }
        
  return type
}

class SearchForm extends Component {

  state = {
    mobileSearch: false,
    searchPlaceholder: `Search`,
    search: '',
    propertyType: '',
    category: this.props.type,
    listing: null,
    bedrooms: null,
    bathrooms: null,
    carparks: null,
    price_min: null,
    price_max: null,
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions)
  }

  componentDidMount() {

    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions)

    if ( window.location?.search !== '') {
            
      let queryStr = window.location?.search,
        queryArr = queryStr.replace('?','').split('&')

      for (let i = 0; i < queryArr.length; i++) {
        
        let qArr = queryArr[i].split('=')
        
        if (qArr[1] === '') continue

        let option = {}
        let field = null
        let name = qArr[0]
        let value = qArr[1]
        
        if ( qArr[0] === '' ) continue
        
        if (name === 'search') {

          option = {
            currentTarget: {
              name: name,
              value: decodeURI( value ),
            }
          }

        } else {

          option = {
            label : parseInt( value )+"+", 
            name: name,
            value: parseInt( value ),
          }

          field = { action: 'select-option', name: name }

        }

        this.handleSelectChange(option, field)
      }
    }
  }

  updateDimensions = () => {

    if(window.innerWidth < 900) {
      this.setState({ searchPlaceholder: `Search` });
    } else {
      this.setState({ searchPlaceholder: `Search by suburb or address` });
    }
  }

  handleSelectChange = (option, field) => {

    const { list, canSearch } = this.props

    let state = {}

    switch (field && field.action) {
      case 'select-option':
        state[field.name] = option
          
        if( canSearch) {
          this.setState(state, this.updateList) 
        } else {
          this.setState(state)
        }

        break
      default:
        let { name, value } = option.currentTarget
        state[name] = value

        if( canSearch) {
          this.setState(state, this.updateList) 
        } else {
          this.setState(state)
        }

        break
    }
  }

  updateList = () => {

    let { search } = this.state

    const { list, set, type, propertyType } = this.props

    const { listing, bedrooms, bathrooms, carparks, price_min, price_max } = this.state

    let results = {}

    search = search.trim().toLowerCase()

    if (list?.edges) {
      results.edges = list.edges.filter((el, i) => {
        const { title, propertyMeta } = el.node
        
        if (bedrooms && (parseInt(propertyMeta.bed) < bedrooms.value)) return false
        if (bathrooms && (parseInt(propertyMeta.bath) < bathrooms.value)) return false
        if (carparks && (parseInt(propertyMeta.carports) < carparks.value)) return false

        if (price_min && (parseInt(propertyMeta.searchPrice) < price_min.value)) return false
        if (price_max && (parseInt(propertyMeta.searchPrice) > price_max.value)) return false
        if (search && title.replace(/,/g, '').toLowerCase().indexOf(search) === -1 ) return false
        return true

      })
    }

    set(results)
  }

  _getSearchVariables() {

    let { search } = this.state

    const { bedrooms, bathrooms, carparks, price_min, price_max } = this.state

    let getUrl = ''

    let holder = '/?'

    let variables = [];

    search = search.trim()

    if( search !== '') {
      variables.push({ name: 'search', value: search})
    }

    if( bedrooms && bedrooms?.value) {

      variables.push({ name: 'bedrooms', value: bedrooms.value})
    }

    if( bathrooms && bathrooms?.value) {
      variables.push({ name: 'bathrooms', value: bathrooms.value})
    }

    if( carparks && carparks?.value ) {
      variables.push({ name: 'carparks', value: carparks.value})
    }

    if( price_min && price_min?.value ) {
      variables.push({ name: 'price_min', value: price_min.value})
    }

    if( price_max && price_max?.value ) {
      variables.push({ name: 'price_max', value: price_max.value})
    }

    for (let i = 0; i < variables.length; i++) {
        getUrl += holder + variables[i].name + '=' + variables[i].value
        holder = '&'
    }

    return getUrl
  }

  _selectChange = option => {

    if (option.value === this.props.type) {
      return
    }

    let getUrl = this._getSearchVariables()

    navigate(`/${option.value}${getUrl}`)
  }

  _selectPropertyType = option => {
    
    let { type, postType } = this.props

    let { value } = option.target

    type = getType(type, value)

    if( type ) {

      let getUrl = this._getSearchVariables()

      navigate(`/${type}${getUrl}`)
    }

    return 
  }

  _handleBlurAway() {

    let { canSearch, type, propertyType } = this.props

    if( !canSearch ) {

      let getUrl = this._getSearchVariables()

      type = getType(type, propertyType)

      navigate(`/${type}${getUrl}`)
    } else {
      this.props.toggleOffSearch()
    }

    return 
  }

  render() {

    let { search, listing, price_min, price_max, bedrooms, bathrooms, carparks, category, searchPlaceholder, commercial_type, mobileSearch } = this.state
    
    let { type, postType, propertyType } = this.props

    type = type ? type.split('-')[0] : null

    type = type ? type.charAt(0).toUpperCase() + type.slice(1) : null

    return (
      <section className={`search ${mobileSearch && 'active'}`}>
        <div className="search__inner">
          <form className='search__form form'>
            <div className="form__row form__row--search">
              <div className='form__select form__select--category'>
                <Select
                  options={ propertyType === "commercial" ? commericalCategories : categories }
                  onChange={this._selectChange}
                  openMenuOnFocus={true}
                  id="category"
                  name="category"
                  value={category}
                  placeholder={type}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  ref={node => this.category = node}
                />
              </div>
              <input 
                className="form__input" 
                type="text" name="search" 
                value={search} 
                placeholder={searchPlaceholder} 
                onChange={this.handleSelectChange} 
                onBlur={() => this._handleBlurAway() } 
                ref={node => this.search = node}
              />
              <button 
                type='button'
                onClick={() => this._handleBlurAway()}
                >
                <Search color='#313131' />
              </button>
            </div>

            <div className='form__row form__row--search-options'>
              <div className='form__radio-group'>
                <div className='form__radio'>
                  <input type='radio' id='residential-search-field' name="listing" value="residential" onChange={this._selectPropertyType} checked={ propertyType === 'residential'} />
                  <label htmlFor='residential-search-field'>Residential</label>    
                </div>
                <div className='form__radio'>
                  <input type='radio' id='commercial-search-field' name="listing" value="commercial" onChange={this._selectPropertyType} checked={ propertyType === 'commercial'}  />
                  <label htmlFor='commercial-search-field'>Commercial</label>
                </div>
              </div>
            </div>

            <div className="form__row--dropdowns">

                <div className='form__select form__select--bed'>
                  <Select
                    options={bed_bath_park}
                    onChange={this.handleSelectChange}
                    openMenuOnFocus={true}
                    id="bedrooms"
                    name="bedrooms"
                    value={bedrooms}
                    placeholder="Bed"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    ref={node => this.bed = node}
                  />
                </div>

                <div className='form__select form__select--bath'>
                  <Select
                    options={bed_bath_park}
                    onChange={this.handleSelectChange}
                    openMenuOnFocus={true}
                    id="bathrooms"
                    name="bathrooms"
                    value={bathrooms}
                    placeholder="Bath"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    ref={node => this.bath = node}
                  />
                </div>

                <div className='form__select form__select--car'>
                  <Select
                    options={bed_bath_park}
                    onChange={this.handleSelectChange}
                    openMenuOnFocus={true}
                    id="carparks"
                    name="carparks"
                    value={carparks}
                    placeholder="Car"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    ref={node => this.car = node}
                  />
                </div>

                <div className='form__select form__select--min-price'>
                  <Select
                    options={type === 'Lease' ? rentPrice : commercialPriceMin}
                    onChange={this.handleSelectChange}
                    openMenuOnFocus={true}
                    id="price_min"
                    name="price_min"
                    value={price_min}
                    placeholder={type === 'lease' ? 'Min Price P.A.' : 'Min Price'}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    ref={node => this.min = node}
                  />
                </div>

                <div className='form__select form__select--max-price'>
                  <Select
                    options={type === 'Lease' ? rentPrice : commercialPriceMax}
                    onChange={this.handleSelectChange}
                    openMenuOnFocus={true}
                    id="price_max"
                    name="price_max"
                    value={price_max}
                    placeholder={type === 'lease' ? 'Max Price P.A.' : 'Max Price'}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    ref={node => this.max = node}

                  />
                </div>

              </div>
          </form>
        </div>
      </section>
    )
  }
}

export default SearchForm
